<template>
    <div v-if="design_comp" class="zt-block comp-block">
        <div class="comp-wrap">
            <div class="comp-logo-wrap">
                <div style="width:160px;height:160px;margin-right:30px;">
                    <div v-if="design_comp.logo" class="bg_img"
                    :style="{backgroundImage: 'url(' + imgUrl+design_comp.logo+'!width_250px' + ')'}"></div>
                    <div v-else class="bg_img"
                    :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                </div>
            </div>
            <div class="comp-info">
                <div class="name">{{design_comp.name}}</div>
                <div class="label-wrap">
                    <label class="color-1">家具设计</label>
                    <label class="color-2">3D渲染</label>
                </div>
                <a @click="toDetail(design_comp.id)">查看详细资质</a>
                <a v-if="type" @click="get_phone(design_comp.id)">
                    <i class="iconfont icon-dianhuabeifen"></i> 联系方式（{{phoneNum && phoneNum.total}}）</a>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" 
          width="500px" :destroy-on-close="true">
          <ItemPhone :type="compType" :compId="compId" @hide="popVisible=false"></ItemPhone>
        </el-dialog>

    </div>
</template>

<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from "@/config";
import { getPhoneNum } from "@/service/company";
import ItemPhone from '@/components/comp/ItemPhone.vue';
export default {
  components: { ItemPhone },
  props: ['design_comp','type'],
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl:STATIC_URL_PRE,
      popTitle: null,
      popVisible: false,
      compId: null,
      compType: null,
      phoneNum: '', // 查看电话号码的数量
    };
  },
  async created() {
        console.log(this.type);
        if (this.type == '_bqgm') {
            this.phoneNum = await getPhoneNum({comp_id:this.design_comp.id,channel:4});
        } else if (this.type == '_fchz') {
            this.phoneNum = await getPhoneNum({comp_id:this.design_comp.id,channel:3});
        }
  },
  methods: {
    goto(path){
        this.$router.push(path);
    },
    toDetail(id) {
      window.open('/zizhi/view/'+id);
    },
    get_phone(id) {
        console.log(this.type);
        if (this.type == '_bqgm') {
            this.compType = 4;
        } else if (this.type == '_fchz') {
            this.compType = 3;
        }
        this.compId = id;
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
  }
};
</script>

<style scoped lang="less">
.comp-block {
    padding: 40px 75px;
    .comp-wrap {
        display: flex;
        padding: 21px 48px 36px;
        width: 100%;
        border:1px solid rgba(204,204,204,1);
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        border-radius:6px;
    }
    .comp-logo-wrap {
        width:160px;
        height:160px;
        margin-right:30px;
    }
    .comp-info {
        a {
            margin-right: 32px;
        }
        .name {
            color: #4D4D4D;
            font-size: 26px;
        }
        .label-wrap {
            display: flex;
            margin: 10px 0 75px;
            label {
                width:88px;
                height:24px;
                border-radius:12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                &.color-1 {
                    color: #F66F6A;
                    background:linear-gradient(180deg,rgba(255,178,150,0.2) 0%,rgba(253,136,149,0.2) 100%);
                }
                &.color-2 {
                    color: #2BBAAE;
                    background:linear-gradient(180deg,rgba(70,184,165,0.2) 0%,rgba(154,235,193,0.2) 100%);
                }
            }
        }
    }
}
.bg_img {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
</style>